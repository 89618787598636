/*=========================================================================================
  File Name: state.js
  Description: Vuex Store - state
==========================================================================================*/

import themeConfig, { colors } from '@/../themeConfig'

// /////////////////////////////////////////////
// Helper
// /////////////////////////////////////////////

// *From Auth - Data will be received from auth provider
const userDefaults = {
  uid         : 0,          // From Auth
  displayName : null, // From Auth
  about       : null,
  photoURL    : null, // From Auth
  status      : null,
  userRole    : 'stranger',
  birthday    : null,
  emails      : [],
  phones      : [],
  addresses   : [],
  
}


const userInfoLocalStorage = JSON.parse(localStorage.getItem('userInfo')) || {}

// Set default values for active-user
// More data can be added by auth provider or other plugins/packages
const getUserInfo = () => {
  return null
}


// Check if device is touch device
// This is used to remove perfect scrollbar from touch devices
// Using Dynamic components
const is_touch_device = () => {
  const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ')
  const mq = function (query) {
    return window.matchMedia(query).matches
  }

  if ('ontouchstart' in window || window.DocumentTouch) {
    return true
  }

  // include the 'heartz' as a way to have a non matching MQ to help terminate the join
  // https://git.io/vznFH
  const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('')
  return mq(query)
}


const menuDefault = [
 
  {
    url: '/',
    name: 'Dashboard',
    icon: 'HomeIcon',
    i18n: 'Dashboard',
  },
  {
    url: '/gebruikers',
    name: 'Gebruikers',
    icon: 'UserIcon',
    i18n: 'Gebruikers',
  },{
    name: 'Groepen',
    icon: 'UsersIcon',
    i18n: 'Groepen',
    submenu: []
    
  },
  {
    url: '/kalender',
    name: 'Kalender',
    icon: 'CalendarIcon',
    i18n: 'Kalender',
  },
  
  {
    name: 'Instellingen',
    icon: 'SettingsIcon',
    i18n: 'Instellingen',
    submenu: [
      {
        url: '/instellingen',
        name: 'Organisatie',
        slug: 'organisatie',
        icon: 'FlagIcon',
      },
      {
        url: '/instellingen/groepen',
        name: 'Groepen',
        slug: 'groepen',
        icon: 'UsersIcon',
      },
      {
        url: '/instellingen/integraties',
        name: 'Integraties',
        slug: 'integraties',
        icon: 'Link2Icon',
      },
    ]
    
  },
]


// /////////////////////////////////////////////
// State
// /////////////////////////////////////////////

export const getInitialState = () => {
  return {
    AppActiveUser           : getUserInfo(),
    menuItems               : menuDefault,
    organisation            : null,
    groups                  : [],
    role                    : null,
    initialLoading          : true,
    pageTitle               : null,

    // Can be used to get current window with
    // Note: Above breakpoint state is for internal use of sidebar & navbar component
   
  }
}

const state = {...getInitialState(),  windowWidth: null};

export default state
