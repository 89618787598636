/*=========================================================================================
  File Name: moduleAuthActions.js
  Description: Auth Module Actions
==========================================================================================*/

import { auth, db, getTimestamp } from '@/firebase/firebaseConfig'

import firebase from 'firebase/app';

import router from '@/router'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import state from '../state';

export default {
  loginAttempt({ dispatch }, payload) {

    // New payload for login action
    const newPayload = {
      userDetails: payload.userDetails,
      notify: payload.notify,
      closeAnimation: payload.closeAnimation,
      next: payload.next
    }

    // If remember_me is enabled change firebase Persistence
    if (!payload.checkbox_remember_me) {

      // Change firebase Persistence
      auth.setPersistence(firebase.auth.Auth.Persistence.SESSION)

        // If success try to login
        .then(function () {
          dispatch('login', newPayload)
        })

        // If error notify
        .catch((error) => {

          // Close animation if passed as payload
          if (payload.closeAnimation) payload.closeAnimation()

          payload.notify({
            component: ToastificationContent,
            props: {
              title: 'Error',
              text: error.message,
              icon: 'AlertCircleIcon',
              variant: 'danger'
            }
          })
        })
    } else {
      // Try to login
      return dispatch('login', newPayload)
    }
  },
  login({ commit, state, dispatch }, payload) {

    // If user is already logged in notify and exit
    if (state.isUserLoggedIn()) {
      // Close animation if passed as payload
      if (payload.closeAnimation) payload.closeAnimation()
      payload.notify({
        component: ToastificationContent,
        props: {


          title: 'Aanmeldpoging mislukt',
          text: 'Je bent reeds aangemeld!',
          icon: 'AlertCircleIcon',
          variant: 'danger'
        }
      })

      return false
    }

    // Try to sigin
    return auth.signInWithEmailAndPassword(payload.userDetails.email, payload.userDetails.password).then((result) => {

      return dispatch('loadUser', { ...payload, result: result }).then(() => {
        return router.push(payload.next || router.currentRoute.query.to || '/');
      })


    }, (err) => {

      // Close animation if passed as payload
      if (payload.closeAnimation) payload.closeAnimation()

      payload.notify({
        component: ToastificationContent,
        props: {
          title: 'Error',
          text: err.message,
          icon: 'AlertCircleIcon',
          variant: 'danger'
        }
      })
    })
  },
  registerUser({ dispatch }, payload) {
    console.log(payload);
    var result = null;
    return auth.createUserWithEmailAndPassword(
      payload.email,
      payload.password
    ).then(res => {
      result = res;
      return db
      .collection("users")
      .doc(result.user.uid)
      .set({
        uid: result.user.uid,
        firstname: payload.firstname,
        lastname: payload.lastname,
        displayName: payload.firstname + " " + payload.lastname,
        email: payload.email,
        emails: [payload.email],
        phone: null,
        phones: [],
        address: {},
        addresses: [],
        lastActivity: getTimestamp(),
        organisations: {},
        organisationIds: [],
      });
    
    }).then(() => {
      return setTimeout(() => {
        return dispatch('loadUser', {
          result,
        })
      }, 100);
    })
    
  },


  loginMicrosoft({ dispatch }, payload) {
    var provider = new firebase.auth.OAuthProvider('microsoft.com');


    return firebase.auth().signInWithPopup(provider)
      .then(async (result) => {
        // CHECK IF USER IS NEW
        if (result.additionalUserInfo.isNewUser) {
          await db
            .collection("users")
            .doc(result.user.uid)
            .set({
              uid: result.user.uid,
              firstname: result.additionalUserInfo.profile.givenName || '',
              lastname: result.additionalUserInfo.profile.surname || '',
              displayName: result.additionalUserInfo.profile.displayName || '',
              email: result.additionalUserInfo.profile.mail || '',
              emails: [result.additionalUserInfo.profile.mail || ''],
              lastActivity: getTimestamp(),
              organisations: {},
              organisationIds: [],
            });
        }
        await setTimeout(() => {
          return dispatch('loadUser', {
            payload,
            result,
          }).then(() => {
            return router.push(payload.next || router.currentRoute.query.to || '/');
          })
        }, 100);
        return payload.closeAnimation();
      })
      .catch((error) => {
        console.log(error.message);
        payload.closeAnimation();
        payload.notify({
          component: ToastificationContent,
          props: {

            title: 'Error',
            text: error.message,
            icon: 'AlertCircleIcon',
            variant: 'danger'
          }
        })
      });
  },

  linkMicrosoft({ dispatch }, payload) {
    var provider = new firebase.auth.OAuthProvider('microsoft.com');


    return firebase.auth().currentUser.linkWithPopup(provider)
      .then(async (result) => {
        payload.notify({
          component: ToastificationContent,
          props: {

            title: 'Je accounts werden gelinkt!',
            text: 'Je kan vanaf nu ook aanmelden met je Microsoft-Account',
            icon: 'CheckIcon',
            variant: 'success'
          }
        })

        return payload.closeAnimation();
      })
      .catch((error) => {
        console.log(error);
        payload.closeAnimation();
        payload.notify({
          component: ToastificationContent,
          props: {

            title: 'Error',
            text: error.message,
            icon: 'AlertCircleIcon',
            variant: 'danger'
          }
        })
      });
  },

  loginGoogle({ dispatch }, payload) {
    var provider = new firebase.auth.GoogleAuthProvider;


    return firebase.auth().signInWithPopup(provider)
      .then(async (result) => {
        // CHECK IF USER IS NEW
        if (result.additionalUserInfo.isNewUser) {
          await db
            .collection("users")
            .doc(result.user.uid)
            .set({
              uid: result.user.uid,
              firstname: result.additionalUserInfo.profile.givenName || '',
              lastname: result.additionalUserInfo.profile.surname || '',
              displayName: result.additionalUserInfo.profile.displayName || '',
              email: result.additionalUserInfo.profile.mail || '',
              emails: [result.additionalUserInfo.profile.mail || ''],
              lastActivity: getTimestamp(),
              organisations: {},
              organisationIds: [],
            });
        }
        await setTimeout(() => {
          return dispatch('loadUser', {
            payload,
            result,
          }).then(() => {
            return router.push(payload.next || router.currentRoute.query.to || '/');
          })
        }, 100);
        return payload.closeAnimation();
      })
      .catch((error) => {
        console.log(error);
        payload.closeAnimation();
        payload.notify({
          component: ToastificationContent,
          props: {

            title: 'Error',
            text: error.message,
            icon: 'AlertCircleIcon',
            variant: 'danger'
          }
        })
      });
  },

  linkGoogle({ dispatch }, payload) {
    var provider = new firebase.auth.GoogleAuthProvider;


    return firebase.auth().currentUser.linkWithPopup(provider)
      .then(async (result) => {
        payload.notify({
          component: ToastificationContent,
          props: {

            title: 'Je accounts werden gelinkt!',
            text: 'Je kan vanaf nu ook aanmelden met je Google-Account',
            icon: 'CheckIcon',
            variant: 'success'
          }
        })

        return payload.closeAnimation();
      })
      .catch((error) => {
        console.log(error);
        payload.closeAnimation();
        payload.notify({
          component: ToastificationContent,
          props: {

            title: 'Error',
            text: error.message,
            icon: 'AlertCircleIcon',
            variant: 'danger'
          }
        })
      });
  },
  loginApple({ dispatch }, payload) {
    var provider = new firebase.auth.OAuthProvider('apple.com');


    return firebase.auth().signInWithPopup(provider)
      .then(async (result) => {
        // CHECK IF USER IS NEW
        if (result.additionalUserInfo.isNewUser) {
          await db
            .collection("users")
            .doc(result.user.uid)
            .set({
              uid: result.user.uid,
              firstname: result.additionalUserInfo.profile.givenName || '',
              lastname: result.additionalUserInfo.profile.surname || '',
              displayName: result.additionalUserInfo.profile.displayName || '',
              email: result.additionalUserInfo.profile.mail || '',
              emails: [result.additionalUserInfo.profile.mail || ''],
              lastActivity: getTimestamp(),
              organisations: {},
              organisationIds: [],
            });
        }
        await setTimeout(() => {
          return dispatch('loadUser', {
            payload,
            result,
          }).then(() => {
            return router.push(payload.next || router.currentRoute.query.to || '/');
          })
        }, 100);
        return payload.closeAnimation();
      })
      .catch((error) => {
        console.log(error);
        payload.closeAnimation();
        payload.notify({
          component: ToastificationContent,
          props: {

            title: 'Error',
            text: error.message,
            icon: 'AlertCircleIcon',
            variant: 'danger'
          }
        })
      });
  },

  linkApple({ dispatch }, payload) {
    var provider = new firebase.auth.OAuthProvider('apple.com');


    return firebase.auth().currentUser.linkWithPopup(provider)
      .then(async (result) => {
        payload.notify({
          component: ToastificationContent,
          props: {

            title: 'Je accounts werden gelinkt!',
            text: 'Je kan vanaf nu ook aanmelden met je Apple-ID',
            icon: 'CheckIcon',
            variant: 'success'
          }
        })

        return payload.closeAnimation();
      })
      .catch((error) => {
        console.log(error);
        payload.closeAnimation();
        payload.notify({
          component: ToastificationContent,
          props: {

            title: 'Error',
            text: error.message,
            icon: 'AlertCircleIcon',
            variant: 'danger'
          }
        })
      });
  },


  loadUser({ commit, dispatch }, payload) {
    commit('SET_LOADING', true, { root: true });
    return db.doc('users/' + payload.result.user.uid).get().then((snap) => {
      if (snap.exists) {

        var user = snap.data();

        // Set FLAG username update required for updating username
        let isUsernameUpdateRequired = false

        this._vm.$ability.update([
          {
            action: 'read',
            subject: 'Auth',
          },
          {
            action: 'read',
            subject: 'User',
          },
        ])


        // if username is provided and updateUsername FLAG is true
        // set local username update FLAG to true
        // try to update username
        if (payload.updateUsername && payload.userDetails.displayName) {

          isUsernameUpdateRequired = true

          dispatch('updateUsername', {
            user: user,
            username: user.displayName,
            notify: payload.notify,
            isReloadRequired: true
          })
        }



        // Close animation if passed as payload
        if (payload.closeAnimation) payload.closeAnimation()



        // if username update is not required
        // just reload the page to get fresh data
        // set new user data in localstorage
        if (!isUsernameUpdateRequired) {
          commit('UPDATE_USER_INFO', user, { root: true })
        }
        if (user.organisationIds.length == 0) {
          console.log('Stop loading');
          dispatch('finishLoading', { root: true });
        }
        var isAdmin = false;
        var orgId = null;
        orgId = localStorage.getItem('orgId');
        console.log(orgId);
        
        Object.keys(user.organisations).forEach((k) => {
          if (user.organisations[k].type == 'admin') {
            if(orgId == null){
              orgId = k;
            }
            this._vm.$ability.update([
              {
                action: 'read',
                subject: 'Auth',
              },
              {
                action: 'read',
                subject: 'User',
              },
              {
                action: 'read',
                subject: 'Member',
              },
              {
                action: 'read',
                subject: 'Admin',
              },
            ])
            isAdmin = true
          }
        })
        dispatch('selectOrganisation', orgId, { root: true });
        if (user.organisationIds.length == 0 || !isAdmin) {
          console.log('Stop loading');
          dispatch('finishLoading', null, { root: true });
        }
        return;
      } else {
        setTimeout(() => {
          dispatch('loadUser', payload)
        }, 100);
      }

    })
  },

  async updateUserProfile({ commit }, payload) {
    try {
      await payload.user.updateProfile({
        displayName: payload.displayName,
        email: payload.email
      });
      await db.collection('users').doc(payload.user.uid).update({
        displayName: payload.displayName || '',
        email: payload.email || '',
        firstname: payload.firstname || '',
        lastname: payload.lastname || '',
        profilePic: payload.profilePic || {}
      })
      const newUserData = Object.assign({}, payload.user.providerData[0])
      newUserData.displayName = payload.displayName
      newUserData.email = payload.email
      commit('UPDATE_USER_INFO', newUserData, { root: true })

      // If reload is required to get fresh data after update
      // Reload current page
      if (payload.isReloadRequired) {
        router.push(router.currentRoute.query.to || '/')
      }
      return true;
    } catch (error) {
      payload.notify({
        component: ToastificationContent,
        props: {

          title: 'Error',
          text: error.message,
          icon: 'AlertCircleIcon',
          variant: 'danger'
        }
      })
    }

  },

  updateUsername({ commit }, payload) {
    payload.user.updateProfile({
      displayName: payload.displayName
    }).then(() => {

      // If username update is success
      // update in localstorage
      const newUserData = Object.assign({}, payload.user.providerData[0])
      newUserData.displayName = payload.displayName
      commit('UPDATE_USER_INFO', newUserData, { root: true })

      // If reload is required to get fresh data after update
      // Reload current page
      if (payload.isReloadRequired) {
        router.push(router.currentRoute.query.to || '/')
      }
    }).catch((error) => {
      payload.notify({
        component: ToastificationContent,
        props: {

          title: 'Error',
          text: error.message,
          icon: 'AlertCircleIcon',
          variant: 'danger'
        }
      })
    })
  },

  async signOut({ commit, dispatch }, payload = true) {
    await firebase.auth().signOut();
    if (payload) {
      router.push({ name: 'auth-login' });
    }
    await Promise.all([dispatch('resetStore', {}, { root: true }), dispatch('userManagement/resetStore', {}, { root: true })]);


  }
}
