/*=========================================================================================
  File Name: moduleCalendarActions.js
  Description: Calendar Module Actions
==========================================================================================*/

import { auth, db } from '@/firebase/firebaseConfig'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import moment from "moment";

export default {
  addEvent ({ commit }, event) {
    // commit('REMOVE_EVENT', event);
    return db.collection("organisations").doc(event.organisation).collection("events").add(event).catch(error => {
      console.log(error);
      return;
    })
  },
  updateEvent ({ commit }, event) {
    // commit('UPDATE_EVENT', event);
    return db.collection("organisations").doc(event.organisation).collection("events").doc(event.id).update(event).catch(error => {
      console.log(error);
      return;
    })
  },
  cancelEvent ({ commit }, event) {
    // commit('UPDATE_EVENT', event);
    return db.collection("organisations").doc(event.organisation).collection("events").doc(event.id).update({cancelled: true}).catch(error => {
      console.log(error);
      return;
    })
  },
  fetchEventsForRange ({ commit }, payload) {
    var query = db.collection("organisations")
    .doc(payload.orgId)
    .collection("events")
    .where('end', '>=', moment(payload.start).toDate())
    .where('end', '<=', moment(payload.end).add(7, 'days').toDate())

    if(payload.groupIds && payload.groupIds.length > 0){
      return query.where('groupIds', 'array-contains-any', payload.groupIds)
        .orderBy("end", "asc")
        .get()
        .then(snap => {
          return handleEventsSnap(snap);
        }).catch(error => {
          console.log(error);
        })
    } else {
      return query
        .orderBy("end", "asc")
        .get()
        .then(snap => {
          return handleEventsSnap(snap);
        }).catch(error => {
          console.log(error);
        })
    }
    
  },
  
  removeEvent ({ commit }, event) {
    return db
        .collection("organisations")
        .doc(event.organisation)
        .collection("events")
        .doc(event.id)
        .delete().then(()=>{
          return commit('REMOVE_EVENT', event.id)
        })
  },
  
}

function handleEventsSnap(snap){
  var activities = [];
          var schedules = [];
          for (let i = 0; i < snap.docs.length; i++) {
            var doc = snap.docs[i];
            schedules.push({ id: doc.id, ...doc.data() });
            activities.push({ id: doc.id, ...doc.data() });

            
            if(activities[i].cancelled){
              schedules[i].title = 'GEANNULEERD ' + schedules[i].name;
            } else {
              schedules[i].title = schedules[i].name;
            }
            schedules[i].calendarId = schedules[i].groupIds[0];
            schedules[i].category = "time";
            activities[i].start = new Date(activities[i].start.seconds*1000);
            activities[i].end = new Date(activities[i].end.seconds*1000);
            schedules[i].start = activities[i].start.toISOString();
            schedules[i].end = activities[i].end.toISOString();
            
          }
          return {schedules: schedules, activities: activities};
}
