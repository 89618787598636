/*=========================================================================================
  File Name: moduleCalendarActions.js
  Description: Calendar Module Actions
==========================================================================================*/

import { db, getTimestamp } from '@/firebase/firebaseConfig'

export default {
 
  fetchUsers ({ commit, state }, orgId) {
    return db.collection("organisations")
        .doc(orgId)
        .collection("users").get().then((querySnap) => {
          var res = [];
          querySnap.docs.forEach(snap => {
            var u = {uoid: snap.id, ...snap.data()};
            if(u.birthday && u.birthday.seconds){
              u.birthday = new Date(u.birthday.seconds*1000);
            }
            res.push(u);
          })
          commit('SET_USERS', res);
          return res;
        }).catch((error) => { throw error; })
  },
  fetchUser (context, payload) {
    return db.collection("organisations")
    .doc(payload.orgId)
    .collection("users").doc(payload.uid).get().then((snap) => {
     return snap.data();
    }).catch((error) => { throw error; })
  },
  updateUser({ commit, state }, payload){
    commit('UPDATE_USER', payload);
    return db.collection('organisations').doc(payload.orgId).collection('users').doc(payload.user.uoid).set(payload.user, {merge: true}).catch((error) => { throw error; })
  },
  removeUser({ commit, state }, payload){
    commit('UPDATE_USER', payload);
    return db.collection('organisations').doc(payload.orgId).collection('users').doc(payload.user.uoid).delete().catch((error) => { throw error; })
  },
  fetchContacts (context, payload) {
    return db.collection("organisations")
    .doc(payload.orgId)
    .collection('contacts').where('children', 'array-contains', payload.uoid).get().then((querySnap) => {
      var res = [];
      querySnap.docs.forEach(snap => {
        res.push({id: snap.id, ...snap.data()});
      })
      
      return res;
    }).catch((error) => { throw error; })
  },
  fetchContact(context, payload){
    return db.collection("organisations")
    .doc(payload.orgId)
    .collection('contacts').doc(payload.id).get().then((snap) => {
        return {id: snap.id, ...snap.data()};
    }).catch((error) => { throw error; })
  },
  fetchAllContacts ({commit}, orgId) {
    return db.collection("organisations")
    .doc(orgId)
    .collection("contacts").get().then((querySnap) => {
      var res = [];
      querySnap.docs.forEach(snap => {
        res.push({id: snap.id, ...snap.data()});
      })
      commit('SET_CONTACTS', res);
      return res;
    }).catch((error) => { throw error; })
  },
  updateContact({ commit, state }, payload){
    commit('UPDATE_CONTACT', payload);
    return db.collection('organisations').doc(payload.orgId).collection('contacts').doc(payload.contact.id).update(payload.contact).catch((error) => { throw error; })
  },
  newContact({ commit, state }, payload){
    commit('ADD_CONTACT', payload);
    return db.collection('organisations').doc(payload.orgId).collection('contacts').add(payload.contact).catch((error) => { throw error; })
  },
  removeContact({ commit, state }, payload){
    commit('REMOVE_CONTACT', payload);
    return db.collection('organisations').doc(payload.orgId).collection('contacts').doc(payload.contact.id).delete().catch((error) => { throw error; })
  },
  fetchMedicals(context, payload) {
    return db.collection("organisations")
    .doc(payload.orgId)
    .collection("users").doc(payload.uid).collection('medical').orderBy('priority', 'desc').get().then((querySnap) => {
      var res = [];
      querySnap.docs.forEach(snap => {
        res.push({id: snap.id, ...snap.data()});
      })
      
      return res;
    }).catch((error) => { throw error; })
  },
  addMedical(context, payload){
    return db.collection("organisations")
    .doc(payload.orgId)
    .collection("users").doc(payload.uid).collection('medical').add(payload.medical).catch((error) => {
      console.log(error);
    })
  },
  updateMedical(context, payload){
    return db.collection("organisations")
    .doc(payload.orgId)
    .collection("users").doc(payload.uid).collection('medical').doc(payload.medical.id).update(payload.medical).catch((error) => {
      console.log(error);
    })
  },
  deleteMedical(context, payload){
    return db.collection("organisations")
    .doc(payload.orgId)
    .collection("users").doc(payload.uid).collection('medical').doc(payload.medical.id).delete().catch((error) => {
      console.log(error);
    })
  },
  changeGroupRole({ commit }, payload){
    return db.collection("organisations")
        .doc(payload.orgId)
        .collection("groups")
        .doc(payload.groupId)
        .collection("members")
        .doc(payload.uoid)
        .set({ type: payload.type }, { merge: true }).then(() => {
          commit('UPDATE_GROUP_TYPE', payload)
        })
    ;
  },
  addUserToGroup({commit}, payload){
    return db
        .collection("organisations")
        .doc(payload.orgId)
        .collection("groups")
        .doc(payload.groupId)
        .collection("members")
        .doc(payload.user.uoid)
        .set(
          {
            type: "member",
            displayName: payload.user.displayName,
            firstname: payload.user.firstname
          },
          { merge: true }
        ).then(() => {
          return commit('ADD_USER_GROUP', payload);
        });
  },
  addUsersToGroup({commit}, payload){
    var batch = db.batch();
    payload.users.forEach(user => {
      batch.set(db
        .collection("organisations")
        .doc(payload.orgId)
        .collection("groups")
        .doc(payload.groupId)
        .collection("members")
        .doc(user.uoid), {
          type: payload.role ?? "member",
          displayName: user.displayName,
          firstname: user.firstname,
          lastUpdate: getTimestamp()
        },
        { merge: true });
      commit('ADD_USER_GROUP', {...payload, user});
    })
    return batch.commit();
  },
  removeUserFromGroup({commit}, payload){
    return db
        .collection("organisations")
        .doc(payload.orgId)
        .collection("groups")
        .doc(payload.groupId)
        .collection("members")
        .doc(payload.user.uoid)
        .delete().then(() => {
          return commit('REMOVE_USER_GROUP', {payload, user: payload.user.uoid})
        });
  },
  removeUsersFromGroup({commit}, payload){
    var batch = db.batch();
    payload.users.forEach(user => {
      batch.delete(db
        .collection("organisations")
        .doc(payload.orgId)
        .collection("groups")
        .doc(payload.groupId)
        .collection("members")
        .doc(user));
      commit('REMOVE_USER_GROUP', {...payload, user})
    })
    return batch.commit();
  },
  async moveUsersToGroup({commit, dispatch}, payload)  {
    await dispatch('addUsersToGroup', {
      users: payload.users,
      orgId: payload.orgId,
      groupId: payload.targetGroupId,
    });
    return dispatch('removeUsersFromGroup', {
      users: payload.users.map((el) => el.uoid),
      orgId: payload.orgId,
      groupId: payload.groupId,
    });
  },
  resetStore({ commit }, ){
    return commit('RESET_STORE');
  }
}
