/*=========================================================================================
  File Name: moduleCalendarMutations.js
  Description: Calendar Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
  ADD_EVENT (state, event) {
    state.events.push(event);
    var s = [...[], ...state.schedules];
    s.push(event);
    const scheduleIndex = s.length-1;
    var gs = "";
    s[scheduleIndex].groupIds.forEach(id => {
      if(gs.length > 0){
        gs += ", ";
      }
      gs += s[scheduleIndex].groups[id].name;
    });
    s[scheduleIndex].title = s[scheduleIndex].name + ' ('+gs+')';
    s[scheduleIndex].calendarId = s[scheduleIndex].groupIds[0];
    s[scheduleIndex].category = "time";

    state.schedules = s;

  },
  SET_EVENTS (state, events) {
    state.events = events
  },
  SET_LABELS (state, labels) {
    state.eventLabels = labels
  },
  UPDATE_EVENT (state, event) {
    const eventIndex = state.events.findIndex((e) => e.id === event.id)
    const scheduleIndex = state.schedules.findIndex((e) => e.id === event.id)
    Object.assign(state.events[eventIndex], event);
    var s = [...[], ...state.schedules];
    Object.assign(s[scheduleIndex], event);
    var gs = "";
    s[scheduleIndex].groupIds.forEach(id => {
      if(gs.length > 0){
        gs += ", ";
      }
      gs += s[scheduleIndex].groups[id].name;
    });
    s[scheduleIndex].title = s[scheduleIndex].name + ' ('+gs+')';
    s[scheduleIndex].calendarId = s[scheduleIndex].groupIds[0];
    s[scheduleIndex].category = "time";
  },
  REMOVE_EVENT (state, eventId) {
    const eventIndex = state.events.findIndex((e) => e.id === eventId)
    const scheduleIndex = state.schedules.findIndex((e) => e.id === eventId)
    state.events.splice(eventIndex, 1);
    state.schedules.splice(scheduleIndex, 1);
  },
  SET_EVENTS (state, events){
    state.events = events;
  },
  SET_SCHEDULES (state, schedules){
    state.schedules = schedules;
  }
}
