import firebase from 'firebase/app';
import moment from 'moment';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/functions';

const userEmulator = false;

const firebaseApp = firebase.initializeApp({
        apiKey: "AIzaSyD3IOC5IiUe_n4VV1ntvGPjQzZkid9bNrE",
        authDomain: "tapp-prod.firebaseapp.com",
        databaseURL: "https://tapp-prod.firebaseio.com",
        projectId: "tapp-prod",
        storageBucket: "tapp-prod.appspot.com",
        messagingSenderId: "700540960074",
        appId: "1:700540960074:web:8f4b0c85d4567ffb1495b3",
        measurementId: "G-G8271S3Y8T"
      });
var f;
if(window.location.hostname === 'localhost' && userEmulator) {
    firebaseApp.firestore().useEmulator('localhost', 8080);
    firebaseApp.functions('europe-west1').useEmulator('localhost', 5001);
    f = firebaseApp.functions('europe-west1')
    firebaseApp.auth().useEmulator('http://localhost:9099');
} else {
    f = firebaseApp.functions('europe-west1');
}

// Export the database for components to use.
// If you want to get fancy, use mixins or provide / inject to avoid redundant imports.
// export const rtdb = firebaseApp.database();
export const db = firebaseApp.firestore();
export const auth = firebaseApp.auth();
export const storage = firebaseApp.storage();
export const functions = f;
export const storageRef = firebaseApp.storage().ref();

export const app = firebaseApp

export function getTimestamp () {
    return firebase.firestore.FieldValue.serverTimestamp();
}
export function convertTimestamp(stamp){
    return moment.unix(stamp).format("DD/MM/YYYY");
}

export const FieldValue = firebase.firestore.FieldValue;
