/*=========================================================================================
  File Name: moduleCalendarMutations.js
  Description: Calendar Module Mutations
==========================================================================================*/

import store from '../index'

export default {
  RESET_STORE (state)
{
  state.users = [];
  state.contacts = [];
},
  SET_USERS (state, users) {
    state.users = users
  },
  SET_CONTACTS (state, contacts) {
    state.contacts = contacts
  },
  UPDATE_GROUP_TYPE (state, payload) {
    var i = state.users.findIndex(x => x.uoid == payload.uoid);
    if(i>-1){
      state.users[i].groups[payload.groupId].type= payload.type;
    }
    
  },
  ADD_USER_GROUP (state, payload) {
    var groups = [... store.state.groups];
    var i = state.users.findIndex(x => x.uoid == payload.user.uoid);
    var groupIndex = groups.findIndex(x => x.id == payload.groupId);
    if(i>-1){
      if(!state.users[i].groupIds){state.users[i].groupIds = []}
      if(!state.users[i].groups){state.users[i].groups = {}}
      state.users[i].groupIds.push(payload.groupId);
      state.users[i].groups[payload.groupId] = {
        type: payload.role ?? "member",
        name: groups[groupIndex].name,
        organisation: groups[groupIndex].organisation,
      };
      
    }
  },
  REMOVE_USER_GROUP (state, payload) {
    var i = state.users.findIndex(x => x.uoid == payload.user);
    if(i>-1){
      delete state.users[i].groups[payload.groupId];
      state.users[i].groupIds.splice(state.users[i].groupIds.indexOf(payload.groupId), 1);
    }
  },
  REMOVE_RECORD (state, itemId) {
    const userIndex = state.users.findIndex((u) => u.id === itemId)
    state.users.splice(userIndex, 1)
  },
  UPDATE_USER (state, payload) {
    var i = state.users.findIndex(x => x.uoid == payload.user.uoid);
    if(i<0){
      state.users.push(payload.user);
    } else {
      state.users[i] = payload.user;
    }
  },
  UPDATE_CONTACT (state, payload) {
    if(state.contacts){
      var i = state.contacts.findIndex(x => x.id == payload.contact.id);
      state.contacts[i] = {...payload.contact, ...state.contacts[i]};
    } else {
      state.contacts = [payload.contact];
    }
  },
  ADD_CONTACT (state, payload) {
    if(state.contacts){
      state.contacts = state.contacts.push(payload.contact);
    } else {
      state.contacts = [payload.contact];
    }
    
  },
  REMOVE_CONTACT (state, payload) {
    if(state.contacts){
      var i = state.contacts.findIndex(x => x.id == payload.contact.id);
      state.contacts = state.contacts.splice(i, 1);
    } 
  },
}
