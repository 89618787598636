/*=========================================================================================
  File Name: mutations.js
  Description: Vuex Store - mutations
==========================================================================================*/

import { getInitialState } from "./state";


const mutations = {

  RESET_STORE: function (state){
    Object.assign(state, getInitialState())
  },

  RESET_ORGANISATION_CHANGE: function(state){
    state.userManagement.users = [];
    state.userManagement.contacts = [];

  },
  // /////////////////////////////////////////////
  // Organisations
  // /////////////////////////////////////////////

  SET_ORGANISATION: function(state, c) {
    state.organisation = c;
    if(state.organisation.roles == null)
    {state.organisation.roles = {}}
  },
  MERGE_ORGANISATION: function(state, c) {
    state.organisation = {...state.organisation, ...c};
    if(state.organisation.roles == null)
    {state.organisation.roles = {}}
  },
  SET_GROUPS: function(state, g) {
    state.groups = g
  },
  

  SET_GROUP: function(state, g) {
    const index = state.groups.findIndex((item) => item.id === g.id)
    state.groups[index] = g;
  },

  REMOVE_GROUP: function(state, g) {
    const index = state.groups.findIndex((item) => item.id === g.id)
    state.groups.splice(index, 1);
  },

  SET_GROUP_MEMBERS: function(state, payload){
    state.groups[payload.id]['members'] = payload.members;
  },


  // /////////////////////////////////////////////
  // COMPONENTS
  // /////////////////////////////////////////////

  // Vertical NavMenu

  RESET_GROUP_TO_MENU (state) {
    state.menuItems[2]['submenu'] = [];
  },

  ADD_GROUP_TO_MENU (state, value) {
    state.menuItems[2]['submenu'].push(
      {
        url: '/groepen/'+value.id,
        name: value.name,
        slug: value.id,
        icon: 'UsersIcon',
        color: value.color
      }
    )
  },

  TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE (state, value) {
    state.isVerticalNavMenuActive = value
  },
  TOGGLE_REDUCE_BUTTON (state, val) {
    state.reduceButton = val
  },
  UPDATE_MAIN_LAYOUT_TYPE (state, val) {
    state.mainLayoutType = val
  },
  UPDATE_VERTICAL_NAV_MENU_ITEMS_MIN (state, val) {
    state.verticalNavMenuItemsMin = val
  },
  UPDATE_VERTICAL_NAV_MENU_WIDTH (state, width) {
    state.verticalNavMenuWidth = width
  },

  SET_PAGE_TITLE(state, title){
    document.title = `${state.organisation ? state.organisation.shortName + ' - ' : ''}${title}` || 'Tapp';
    state.pageTitle = title;
  },
    


  // ////////////////////////////////////////////
  // UI
  // ////////////////////////////////////////////

  TOGGLE_CONTENT_OVERLAY (state, val) { state.bodyOverlay       = val   },
  UPDATE_PRIMARY_COLOR (state, val)   { state.themePrimaryColor = val   },
  UPDATE_THEME (state, val)           { state.theme             = val   },
  UPDATE_WINDOW_WIDTH (state, width)  { state.windowWidth       = width },
  UPDATE_WINDOW_SCROLL_Y (state, val) { state.scrollY           = val   },


  // /////////////////////////////////////////////
  // User/Account
  // /////////////////////////////////////////////

  // Updates user info in state
  UPDATE_USER_INFO (state, payload) {

    if(!state.AppActiveUser) state.AppActiveUser = {};

    for (const property of Object.keys(payload)) {

      if (payload[property] !== null) {
        // If some of user property is null - user default property defined in state.AppActiveUser
        state.AppActiveUser[property] = payload[property]

      }


    }
  },

  SET_CURRENT_ROLE(state, payload){
    state.role = payload;
  },

  SET_LOADING(state, payload){
    state.initialLoading = payload;
  }
}

export default mutations

