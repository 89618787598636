import Vue from 'vue'
import Vuex from 'vuex'

import state from './state'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'

import auth from './auth/moduleAuth';
import userManagement from './user-management/moduleUserManagement';
import calendar from './calendar/moduleCalendar';

import VuexPersistence from 'vuex-persist'

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: state =>
    Object.keys(state).reduce((acc, curr) => {
      if (!['initialLoading', 'AppActiveUser', 'userManagement/users', 'userManagement/contacts'].includes(curr)) acc[curr] = state[curr];
      return acc;
    }, {})
})

export default new Vuex.Store({
  getters,
  mutations,
  state,
  actions,
  modules: {
    app,
    appConfig,
    auth,
    userManagement,
    calendar,
    verticalMenu,
  },
  strict: process.env.DEV,
  plugins: [vuexLocal.plugin]
})
