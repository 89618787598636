/*=========================================================================================
  File Name: getters.js
  Description: Vuex Store - getters
==========================================================================================*/

// added so later we can keep breakpoint in sync automatically using this config file
// import tailwindConfig from "../../tailwind.config.js"

var roleMap = {
  leader: 'Leider',
  leaders: 'Leiding',
  member: 'Lid',
  members: 'Leden',
  admin: 'Beheerder',
  admins: 'Beheerders'
};


const getters = {

  // COMPONENT
  // vx-autosuggest
  // starredPages: state => state.navbarSearchAndPinList.data.filter((page) => page.highlightAction),
  windowBreakPoint: state => {

    // This should be same as tailwind. So, it stays in sync with tailwind utility classes
    if (state.windowWidth >= 1200) return 'xl'
    else if (state.windowWidth >= 992) return 'lg'
    else if (state.windowWidth >= 768) return 'md'
    else if (state.windowWidth >= 576) return 'sm'
    else return 'xs'
  },

  scrollbarTag: state => {
    return state.is_touch_device ? 'div' : 'VuePerfectScrollbar'
  },

  getRoleSingular:  (state, getters) => (role) => {
    if(state.organisation){
      return state.organisation[role] ? getters.titleCase(state.organisation[role]) : roleMap[role];
    } else {
      return roleMap[role]
    }
    
  },
  getRoleMultiple: (state, getters) => (role) => {
    if(state.organisation){
      return state.organisation[role+"s"] ? getters.titleCase(state.organisation[role+"s"]) : state.organisation[role] ? getters.titleCase(state.organisation[role]+"s") : roleMap[role+"s"];
    }
    return roleMap[role+"s"];
  },

  titleCase: state => (str) => {
    var splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
      // You do not need to check if i is larger than splitStr length, as your for does that for you
      // Assign it back to the array
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(' ');
  },

  getGroups: state => () => {
    return state.groups;
  }
}

export default getters
