import Vue from 'vue'
import VueRouter from 'vue-router'

import { canNavigate } from '@/libs/acl/routeProtection'
import ability from '@/libs/acl/ability'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'

import store from '@/store/index';

import { auth } from '@/firebase/firebaseConfig'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Dashboard',
        resource: 'Admin',
        action: 'read',
        breadcrumb: [
          {
            text: 'Dashboard',
            active: true,
          },
        ],
      },
    },
    {
      path: '/stats',
      name: 'statistics',
      component: () => import('@/views/Statistics.vue'),
      meta: {
        pageTitle: 'Statistieken',
        resource: 'Admin',
        action: 'read',
        breadcrumb: [
          {
            text: 'Statistieken',
            active: true,
          },
        ],
      },
    },
    {
      path: '/kalender',
      name: 'calendar',
      component: () => import('@/views/calendar/Calendar.vue'),
      meta: {
        pageTitle: 'Kalender',
        resource: 'Admin',
        action: 'read',
        breadcrumb: [
          {
            text: 'Kalender',
            active: true,
          },
        ],
      },
    },
    {
      path: '/gebruikers',
      name: 'user-list',
      component: () => import('@/views/users/users-list/UsersList.vue'),
      meta: {
        pageTitle: 'Gebruikers',
        resource: 'Admin',
        action: 'read',
        breadcrumb: [
          {
            text: 'Gebruikers',
            active: true,
          },
        ],
      },
    },
    {
      path: '/gebruikers/:uoid',
      name: 'users-edit',
      component: () => import('@/views/users/users-edit/UsersEdit.vue'),
      meta: {
        pageTitle: 'Gebruikers',
        resource: 'Admin',
        action: 'read',
        breadcrumb: [
          {
            text: 'Gebruikers',
            to: {name: 'user-list'},
            active: false,
          },
          {
            text: 'Detail',
            active: true,
          },
        ],
      },
    },
    {
      path: '/groepen',
      name: 'groups',
      component: () => import('@/views/groups/GroupList.vue'),
      meta: {
        pageTitle: 'Groepen',
        resource: 'Admin',
        action: 'read',
        breadcrumb: [
          {
            text: 'Groepen',
            active: true,
          },
        ],
      },
    },
    {
      path: '/groepen/:id',
      name: 'group-detail',
      component: () => import('@/views/groups/group-detail/GroupDetail.vue'),
      meta: {
        pageTitle: 'Groepen',
        resource: 'Admin',
        action: 'read',
        breadcrumb: [
          {
            text: 'Groepen',
            to: {name: 'groups'},
            active: false,
          },
          {
            text: 'Detail',
            active: true,
          },
        ],
      },
    },
    {
      path: '/contacten',
      name: 'contact-list',
      component: () => import('@/views/contacts/contacts-list/ContactsList.vue'),
      meta: {
        pageTitle: 'Contacten',
        resource: 'Admin',
        action: 'read',
        breadcrumb: [
          {
            text: 'Contacten',
            active: true,
          },
        ],
      },
    },
    {
      path: '/contacten/:id',
      name: 'contacts-edit',
      component: () => import('@/views/contacts/contacts-edit/ContactEdit.vue'),
      meta: {
        pageTitle: 'Contacten',
        resource: 'Admin',
        action: 'read',
        breadcrumb: [
          {
            text: 'Contacten',
            to: {name: 'contact-list'},
            active: false,
          },
          {
            text: 'Detail',
            active: true,
          },
        ],
      },
    },
    {
      path: '/account-instellingen',
      name: 'account-settings',
      component: () => import('@/views/account-settings/AccountSetting.vue'),
      meta: {
        pageTitle: 'Account Instellingen',
        resource: 'User',
        action: 'read',
      },
    },
    {
      path: '/instellingen',
      name: 'organisation-settings',
      component: () => import('@/views/organisation-settings/OrganisationSettings.vue'),
      meta: {
        pageTitle: 'Instellingen',
        resource: 'Admin',
        action: 'read',
      },
    },
    {
      path: '/integraties',
      name: 'integrations',
      component: () => import('@/views/integrations/IntegrationsList.vue'),
      meta: {
        pageTitle: 'Integraties',
        resource: 'Admin',
        action: 'read',
      },
    },
    {
      path: '/importeren',
      name: 'import',
      component: () => import('@/views/integrations/excel/importExcel.vue'),
      meta: {
        pageTitle: 'Importeren',
        resource: 'Admin',
        action: 'read',
      },
    },
    {
      path: '/importeren/groepen',
      name: 'importgroups',
      component: () => import('@/views/integrations/excel/importExcelGroups.vue'),
      meta: {
        pageTitle: 'Groepen Importeren',
        resource: 'Admin',
        action: 'read',
      },
    },
    {
      path: '/importeren/profielfotos',
      name: 'importProfilePictures',
      component: () => import('@/views/integrations/importProfilePics.vue'),
      meta: {
        pageTitle: "Profielfoto's Importeren",
        resource: 'Admin',
        action: 'read',
      },
    },
    {
      path: '/aansluiten',
      name: 'enroll',
      component: () => import('@/views/enroll/Enroll.vue'),
      meta: {
        pageTitle: 'Aansluiten',
        layout: 'full',
        resource: 'Auth',
        action: 'read',
      },
    },
    {
      path: '/aanmelden',
      name: 'auth-login',
      component: () => import('@/views/authentication/LoginWrapper.vue'),
      meta: {
        pageTitle: 'Aanmelden',
        layout: 'full',
        resource: 'Auth',
        action: 'read',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/registreren',
      name: 'auth-register',
      component: () => import('@/views/authentication/Register.vue'),
      meta: {
        pageTitle: 'Registreren',
        layout: 'full',
        resource: 'Auth',
        action: 'read',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/wachtwoord-vergeten',
      name: 'auth-forgot-password',
      component: () => import('@/views/authentication/ForgotPassword.vue'),
      meta: {
        pageTitle: 'Wachtwoord vergeten',
        layout: 'full',
        resource: 'Auth',
        action: 'read',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/wachtwoord-herstellen',
      name: 'auth-reset-password',
      component: () => import('@/views/authentication/ResetPassword.vue'),
      meta: {
        pageTitle: 'Wachtwoord herstellen',
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/koppelen',
      name: 'link-account',
      component: () => import('@/views/authentication/LinkAccount.vue'),
      meta: {
        pageTitle: 'Account koppelen',
        layout: 'full',
        resource: 'Auth',
      },
    },
    {
      path: '/auth',
      name: 'auth',
      component: () => import('@/views/authentication/Auth.vue'),
      meta: {
        pageTitle: 'Link verwerken...',
        layout: 'full',
        resource: 'Auth',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        pageTitle: 'Error 404',
        layout: 'full',
        resource: 'Auth',
        action: 'read',
      },
    },
    {
      path: '/verboden-toegang',
      name: 'not-authorized',
      component: () => import('@/views/miscellaneous/NotAuthorized.vue'),
      meta: {
        pageTitle: 'Verboden toegang',
        layout: 'full',
        resource: 'Auth',
        action: 'read',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  if(store.state.initialLoading){
    store.watch(
      (state) => {
          return state.initialLoading;
      },
      () => {
        if(!store.state.initialLoading) {
          checkRoute(to, _, next);
        }
      },
  );
  } else {
    checkRoute(to, _, next);
  }
});
function checkRoute(to, _, next) {
  const isLoggedIn = auth.currentUser ? true : false;
  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login', query: {to: to.fullPath} })

    // If logged in => not authorized
    return next(getHomeRouteForLoggedInUser(store.state.role));
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData();
    next(getHomeRouteForLoggedInUser(store.state.role))
  }
  store.commit('SET_PAGE_TITLE', to.meta.pageTitle);
  return next()
}

export default router
