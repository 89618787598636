/*=========================================================================================
  File Name: actions.js
  Description: Vuex Store - actions
==========================================================================================*/
import { auth, db, functions, storageRef } from '@/firebase/firebaseConfig'

import acl from '@/libs/acl';
import router from '@/router';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const actions = {

  resetStore({ commit }) {
    localStorage.removeItem('orgId');
    commit('RESET_STORE');
  },

  resetOrganisationChange({commit}){
    commit('RESET_ORGANISATION_CHANGE')
  },

  // /////////////////////////////////////////////
  // COMPONENTS
  // /////////////////////////////////////////////

  // Vertical NavMenu
  updateVerticalNavMenuWidth({ commit }, width) {
    commit('UPDATE_VERTICAL_NAV_MENU_WIDTH', width)
  },

  // /////////////////////////////////////////////
  // UI
  // /////////////////////////////////////////////

  toggleContentOverlay({ commit }) {
    commit('TOGGLE_CONTENT_OVERLAY')
  },
  updateTheme({ commit }, val) {
    commit('UPDATE_THEME', val)
  },

  // /////////////////////////////////////////////
  // User/Account
  // /////////////////////////////////////////////

  updateUserInfo({ commit }, payload) {
    commit('UPDATE_USER_INFO', payload)
  },
  updateUserRole({ dispatch }, payload) {
    // Change client side
    payload.aclChangeRole(payload.userRole)

    // Make API call to server for changing role

    // Change userInfo in localStorage and store
    dispatch('updateUserInfo', { userRole: payload.userRole })
  },

  // /////////////////////////////////////////////
  // Organisations
  // /////////////////////////////////////////////

  loadGroups({ commit, dispatch, state }, payload) {
    var role = state.AppActiveUser.organisations[payload] ? state.AppActiveUser.organisations[payload].type : 'member';
    if (role == 'admin') {
      db.collection('organisations').doc(payload).collection('groups').where('organisationId', '==', payload).orderBy('o').get().then((snap) => {
        var groups = [];
        snap.forEach(doc => {
          groups.push({ id: doc.id, ...doc.data() });
        });
        commit('SET_GROUPS', groups);
      });
    } else {
    }
  },
  selectOrganisation({ commit, dispatch, state }, payload) {
    // dispatch('updateUserRole',{aclChangeRole: this._vm.$acl.change, userRole: state.AppActiveUser.organisations[payload].type});
    localStorage.setItem('orgId', payload);
    dispatch('loadGroups', payload);
    dispatch('resetOrganisationChange');
    return db.collection('organisations').doc(payload).onSnapshot(snap => {
      var o = snap.data();
      // Remove loading animation
      dispatch('finishLoading', null, { root: true });

      commit('SET_ORGANISATION', o);
    });
  },

  finishLoading({ commit }) {
    commit('SET_LOADING', false);

    const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
      appLoading.style.display = 'none'
    }
  },

  updateOrganisation({ commit, state }, payload) {
    commit('MERGE_ORGANISATION', payload.organisation);

    return db.collection('organisations').doc(state.organisation.id).update(payload.organisation).then(() => {
      payload.notify({
        component: ToastificationContent,
        props: {
          title: 'Wijzigingen opgeslaan!',
          icon: 'AlertCircleIcon',
          variant: 'success'
        }
      })
    }).catch(error => {
      payload.notify({
        component: ToastificationContent,
        props: {
          title: 'Error',
          text: error.message,
          icon: 'AlertCircleIcon',
          variant: 'danger'
        }
      })
    })
  },

  updateGroupOrder({ commit }, payload) {
    var batch = db.batch();
    payload.groups.forEach((g, i) => {
      if (!g.o || g.o != i) {
        g.o = i;
        commit('SET_GROUP', g);
        batch.update(db.collection('organisations').doc(g.organisationId).collection('groups').doc(g.id), { o: g.o });
      }
    })

    commit('SET_GROUPS', payload.groups);

    return batch.commit().then(() => {
      payload.notify({
        component: ToastificationContent,
        props: {
          title: 'Wijzigingen opgeslaan!',
          icon: 'AlertCircleIcon',
          variant: 'success'
        }
      })
    }).catch(error => {
      payload.notify({
        component: ToastificationContent,
        props: {
          title: 'Error',
          text: error.message,
          icon: 'AlertCircleIcon',
          variant: 'danger'
        }
      })
    });
  },
  
  updateGroupActive({ commit }, payload) {
    var batch = db.batch();
    payload.groups.forEach((g, i) => {
      g.active = payload.active;
      commit('SET_GROUP', g);
      batch.update(db.collection('organisations').doc(g.organisationId).collection('groups').doc(g.id), { active: payload.active });
    })

    return batch.commit().then(() => {
      payload.notify({
        component: ToastificationContent,
        props: {
          title: 'Wijzigingen opgeslaan!',
          icon: 'AlertCircleIcon',
          variant: 'success'
        }
      })
    }).catch(error => {
      payload.notify({
        component: ToastificationContent,
        props: {
          title: 'Error',
          text: error.message,
          icon: 'AlertCircleIcon',
          variant: 'danger'
        }
      })
    });
  },
  removeGroups({ commit }, payload) {
    var batch = db.batch();
    payload.groups.forEach((g, i) => {
      commit('REMOVE_GROUP', g);
      batch.delete(db.collection('organisations').doc(g.organisationId).collection('groups').doc(g.id));
    })

    return batch.commit().then(() => {
      payload.notify({
        component: ToastificationContent,
        props: {
          title: 'Groepen verwijderd!',
          icon: 'AlertCircleIcon',
          variant: 'success'
        }
      })
    }).catch(error => {
      payload.notify({
        component: ToastificationContent,
        props: {
          title: 'Error',
          text: error.message,
          icon: 'AlertCircleIcon',
          variant: 'danger'
        }
      })
    });
  },
  updateGroup({ commit }, payload) {
    commit('SET_GROUP', payload.group);
    console.log({
      name: payload.group.name,
      color: payload.group.color,
      logo: payload.group.logo ?? null,
      active: payload.group.active
    });

    console.log('action');
    return db.collection('organisations').doc(payload.group.organisationId).collection('groups').doc(payload.group.id).update({
      name: payload.group.name,
      color: payload.group.color,
      logo: payload.group.logo ?? null,
      active: payload.group.active
    }).then(() => {
      payload.notify({
        component: ToastificationContent,
        props: {
          title: 'Wijzigingen opgeslaan!',
          icon: 'AlertCircleIcon',
          variant: 'success'
        }
      })
    }).catch(error => {
      payload.notify({
        component: ToastificationContent,
        props: {
          title: 'Error',
          text: error.message,
          icon: 'AlertCircleIcon',
          variant: 'danger'
        }
      })
    })
  },

  fetchGroupMembers({ commit, state }, payload) {
    return db
      .collection("organisations")
      .doc(payload.orgId)
      .collection("users")
      .where("groupIds", "array-contains", payload.groupId)
      .orderBy("lastname")
      .orderBy('firstname')
      .get().then((snap) => {
        var res = [];
        snap.forEach(doc => {
          res.push({ uoid: doc.id, ...doc.data() });
        });
        commit('SET_GROUP_MEMBERS', { id: payload.groupId, members: res });
      })
  },

  fetchGroup({commit, state}, payload){
    if(state.groups && state.groups.findIndex(i => i.id == payload.groupId)>-1){
      var i = state.groups.findIndex(i => i.id == payload.groupId);
      return state.groups[i];
    } else {
      return db
      .collection("organisations")
      .doc(payload.orgId)
      .collection("groups")
      .doc(payload.groupId)
      .get().then((snap) => {
          return { uoid: doc.id, ...doc.data() };
      });
    }
  
  },

  async uploadImageToPath({ commit, dispatch, state }, payload) {
    var sRef = storageRef.child(payload.imageName);
    var snapshot = await sRef.put(payload.file);

    var processed = await functions.httpsCallable('processImageFromPath')({ path: sRef.fullPath });
    return processed.data;
    // return dispatch('updateOrganisation', {organisation: {logo: processed.data}, notify: payload.notify})

  }

}

export default actions
